<template lang="pug">
    section.data
        transition(:name="transitionName", mode="out-in")
            img.illn(
                :src="require(`../../../../../../assets/images/articles/1/algorithm/mobile/illn_${active + 1}.svg`)",
                alt="Иллюстрация к тезису",
                :key="`img__${active + 1}`"
            )
        .wrap(
            @touchstart="(ev) => handleTouch(ev, true)",
            @touchend="(ev) => handleTouch(ev, false)",
        )
            .controls
                article-icon.side.left(src="hex_green", @click="() => setActive(false)")
                .main-control
                    article-icon.hex(src="hex")
                    transition(:name="transitionName", mode="out-in")
                        img.main-icon(
                            :src="require(`../../../../../../assets/images/articles/1/algorithm/mobile/icon_${active + 1}.svg`)",
                            alt="Иконка тезиса",
                            :key="`icon__${active + 1}`",
                            :style="{ '--delay': '0.035s' }"
                        )
                    button.control.dec(@click="() => setActive(false)")
                        img.control-image(src="../../../../../../assets/images/icons/arrow.svg", alt="<")
                    button.control.inc(@click="() => setActive(true)")
                        img.control-image(src="../../../../../../assets/images/icons/arrow.svg", alt=">")
                article-icon.side.right(src="hex_green", @click="() => setActive(true)")
                .scale
                    .inner
            .external-links-wrap
                algorithm-link.external-link(
                    v-for="link in links[active]",
                    :key="link.text",
                    :text="link.text",
                    :href="link.href"
                )
            transition(:name="transitionName", mode="out-in")
                .content(:key="illn.key", :style="{ '--delay': '0.1s' }")
                    component.content-illn(:is="illn.component")
</template>

<script>
import ArticleIcon from '../../../../Icon.vue';
import AlgorithmLink from './Link.vue';

/* eslint-disable import/no-unresolved */
import Illn1 from '../../../../../../assets/images/articles/1/algorithm/mobile/1.svg?inline';
import Illn2 from '../../../../../../assets/images/articles/1/algorithm/mobile/2.svg?inline';
import Illn3 from '../../../../../../assets/images/articles/1/algorithm/mobile/3.svg?inline';
/* eslint-enable import/no-unresolved */

const illustrations = [
    Illn1,
    Illn2,
    Illn3,
];

export default {
    name: 'algorithm-mobile',
    components: {
        ArticleIcon,
        AlgorithmLink,
    },
    props: {
        links: {
            type: Array,
            required: () => true,
        },
    },
    computed: {
        illn() {
            return {
                component: illustrations[this.active] || null,
                key: `${this.active}__${illustrations.length}`,
            };
        },
    },
    data: () => ({
        active: 0,
        touch: 0,
        transitionName: 'slide-right',
    }),
    methods: {
        handleTouch(ev, isStart = false) {
            if (isStart) {
                this.touch = ev.touches[0].clientX;
            } else {
                const { clientX: endX } = ev.changedTouches[0];
                const diff = endX - this.touch;

                if (Math.abs(diff) > 100) {
                    this.setActive(diff < 0);
                }
            }
        },
        setActive(increment = true) {
            const check = increment ? 2 : 0;
            const fallback = increment ? 0 : 2;
            const incrementCount = increment ? 1 : -1;
            const modifier = increment ? 'right' : 'left';

            this.active = this.active === check ? fallback : this.active + incrementCount;
            this.transitionName = `slide-${modifier}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.data {
    --trf: 24px;
    --delay: 0s;

    width: 100%;

    .illn {
        max-width: var(--default-max-width);
        max-height: rem-mobile(210);
        margin: rem-mobile(42) auto rem-mobile(60);
        display: block;
        object-fit: contain;
    }

    .controls {
        width: 100%;
        margin: rem-mobile(60) 0 rem-mobile(16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .side {
            width: rem-mobile(76);
            height: rem-mobile(66);
            transform: translateX(-30%);

            &.right {
                transform: translateX(30%);
            }

            ::v-deep img {
                height: 100%;
            }
        }

        .scale {
            width: 100%;
            height: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            z-index: -1;
            background-color: var(--light-green);

            .inner {
                width: rem-mobile(156);
                height: 100%;
                background-color: var(--violet);
                transform-origin: 50% 50%;
            }
        }

        .main-control {
            width: rem-mobile(126);
            height: rem-mobile(108);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .hex {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;

                ::v-deep img {
                    height: 100%;
                }
            }

            .main-icon {
                max-width: 65%;
                max-height: 55%;
                position: relative;
                z-index: 2;
                object-fit: contain;
            }

            .control {
                width: rem-mobile(8);
                height: rem-mobile(24);
                display: block;
                position: absolute;
                top: 50%;
                right: 100%;
                transform: translate(#{rem-mobile(-22)}, -50%);

                &::before {
                    content: '';
                    width: 300%;
                    height: 200%;
                    position: absolute;
                    top: -50%;
                    left: -100%;
                }

                .control-image {
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: relative;
                    object-fit: contain;
                    transform-origin: 50% 50%;
                }

                &.inc {
                    left: 100%;
                    right: unset;
                    transform: translate(#{rem-mobile(22)}, -50%);

                    .control-image {
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }

    .external-links-wrap {
        width: rem-mobile(220);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .content {
        width: 100%;
        max-width: var(--default-max-width);
        margin: rem-mobile(16) auto;
        padding: 0 var(--offset-x);

        .content-illn {
            width: 100%;
            object-fit: contain;
        }
    }
}

.slide-left {
    &-enter {
        opacity: 0;
        transform: translateX(calc(-1 * var(--trf)));

        &-to {
            opacity: 1;
            transform: translateX(0);
        }

        &-active {
            transition: opacity 0.25s ease-out-quad, transform 0.4s ease-out-quad;
            transition-delay: var(--delay);
        }
    }

    &-leave {
        opacity: 1;
        transform: translateX(0);

        &-to {
            opacity: 0;
            transform: translateX(var(--trf));
        }

        &-active {
            transition: opacity 0.3s ease-in-quad, transform 0.4s ease-in-quad;
            transition-delay: var(--delay);
        }
    }
}

.slide-right {
    &-enter {
        opacity: 0;
        transform: translateX(var(--trf));

        &-to {
            opacity: 1;
            transform: translateX(0);
        }

        &-active {
            transition: opacity 0.25s ease-out-quad, transform 0.4s ease-out-quad;
            transition-delay: var(--delay);
        }
    }

    &-leave {
        opacity: 1;
        transform: translateX(0);

        &-to {
            opacity: 0;
            transform: translateX(calc(-1 * var(--trf)));
        }

        &-active {
            transition: opacity 0.3s ease-in-quad, transform 0.4s ease-in-quad;
            transition-delay: var(--delay);
        }
    }
}
</style>
