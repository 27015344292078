<template lang="pug">
    a.link-wrap(
        :href="href",
        target="_blank",
        rel="noopener noreferrer",
        :aria-label="text"
        @click="$analytics.linkClick(href)"
    )
        .inner-wrap
            article-icon.icon(src="external")
            ui-paragraph.text(v-html="text")
</template>

<script>
import ArticleIcon from '../../../../Icon.vue';
import UiParagraph from '../../../../../ui/Paragraph.vue';

export default {
    name: 'algorithm-link',
    components: {
        ArticleIcon,
        UiParagraph,
    },
    props: {
        href: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.link-wrap {
    height: rem(48);
    margin: rem(6) 0;
    padding: 0 rem(24);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    text-decoration: none;
    background-image: url('../../../../../../assets/images/icons/hex_wide.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    transform: translateX(0);
    transition: transform 0.25s ease-in-quad;

    &:hover {
        transform: translateX(6px);
        transition: transform 0.2s ease-out-quad;
    }

    .inner-wrap {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
            width: rem(14);
            height: rem(14);
            margin-right: rem(8);

            ::v-deep img {
                width: 100%;
                height: 100%;
                object-fit: contain !important;
                vertical-align: unset;
            }
        }
    }

    .text {
        font-family: var(--font-accent);
        font-size: rem(18);
        font-weight: 700;
        color: white;
        line-height: 1;
    }

    @media (--viewport-tablet) {
        width: unset;
        height: rem-mobile(48);
        margin: rem-mobile(6) 0;
        padding: 0 rem-mobile(24);
        transition: none;

        &:hover {
            transform: none;
            transition: none;
        }

        .inner-wrap {
            .icon {
                width: rem-mobile(14);
                height: rem-mobile(14);
                margin-right: rem-mobile(8);
            }
        }

        .text {
            font-size: rem-mobile(18);
        }
    }
}
</style>
