<template lang="pug">
    article.algorithm
        ui-title.title(v-html="title", variant="subtitle", component="h2")
        client-only
            algorithm-mobile(v-if="$platform.isMobile", :links="links")
            algorithm-desktop(v-else, :links="links")
</template>

<script>
import AlgorithmDesktop from './Algorithm/Desktop.vue';
import AlgorithmMobile from './Algorithm/Mobile.vue';
import UiTitle from '../../../../ui/Title.vue';

/* eslint-disable max-len */
const linksEnum = {
    covid19: 'https://www.labquest.ru/covid19/?utm_source=lenta.ru&utm_campaign=tspot-generium',
    tspot: 'https://www.labquest.ru/articles/ne-tolko-koronavirus-epidemiya-tuberkuleza-vo-vremya-covid-19/?utm_source=lenta.ru&utm_campaign=tspot-generium',
};
/* eslint-enable max-len */

export default {
    name: 'algorithm',
    components: {
        AlgorithmDesktop,
        AlgorithmMobile,
        UiTitle,
    },
    computed: {
        title: () => '<em>Алгоритм.</em> Лабораторные методы диагностики COVID-19 и&nbsp;туберкулеза в&nbsp;следующих ситуациях:',
        links: () => ([
            [
                {
                    text: '<nobr>Лабораторная диагностика</nobr><br/><nobr>COVID-19</nobr>',
                    href: linksEnum.covid19,
                },
                {
                    text: 'Т-СПОТ.ТБ',
                    href: linksEnum.tspot,
                },
            ],
            [
                {
                    text: 'Т-СПОТ.ТБ',
                    href: linksEnum.tspot,
                },
                {
                    text: '<nobr>Тест на&nbsp;антитела</nobr><br/><nobr>&laquo;Антигма-Скрин&raquo;</nobr>',
                    href: linksEnum.covid19,
                },
                {
                    text: '<nobr>Тест на&nbsp;антитела IgG</nobr><br/><nobr>&laquo;Антигма-G&raquo;</nobr>',
                    href: linksEnum.covid19,
                },
            ],
            [
                {
                    text: 'Т-СПОТ.ТБ',
                    href: linksEnum.tspot,
                },
                {
                    text: '<nobr>Тест на&nbsp;антитела IgG</nobr><br/><nobr>&laquo;Антигма-G&raquo;</nobr>',
                    href: linksEnum.covid19,
                },
            ],
        ]),
    },
};
</script>

<style lang="scss" scoped>
.algorithm {
    width: rem(750);
    margin: rem(54) auto;

    .title {
        width: rem(560);
    }

    @media (--viewport-tablet) {
        width: 100%;
        margin: rem-mobile(46) auto;

        .title {
            width: 100%;
            padding: 0 var(--offset-x);
        }
    }
}
</style>
