<template lang="pug">
    section.data
        aside.steps
            .step(
                v-for="(step, index) in data",
                :key="index",
                :class="{ 'active': index === active }",
                @click="active = index",
                role="button"
            )
                .icon-wrap(aria-hidden="true")
                    article-icon.icon(src="hex_green")
                    article-icon.icon.active-icon(src="hex")
                    span.count {{ index + 1 }}
                ui-title.title(v-html="step", variant="subtitle-2", component="p", :style="{ textTransform: 'uppercase' }")
                .active-wrap(aria-hidden="true")
                    article-icon.icon(src="hex")
                    img.pointer(src="../../../../../../assets/images/articles/1/algorithm/pointer.svg", alt="")
                    .external-links-wrap
                        algorithm-link.external-link(
                            v-for="link in links[index]",
                            :key="link.text",
                            :text="link.text",
                            :href="link.href"
                        )
            .scale(aria-hidden="true")
                .inner(:style="style.scale")
        transition(:name="transitionName", mode="out-in")
            .content(:key="illn.key")
                component.illn(:is="illn.component")
</template>

<script>
import UiParagraph from '../../../../../ui/Paragraph.vue';
import UiTitle from '../../../../../ui/Title.vue';
import ArticleIcon from '../../../../Icon.vue';
import AlgorithmLink from './Link.vue';

/* eslint-disable import/no-unresolved */
import Illn1 from '../../../../../../assets/images/articles/1/algorithm/1.svg?inline';
import Illn2 from '../../../../../../assets/images/articles/1/algorithm/2.svg?inline';
import Illn3 from '../../../../../../assets/images/articles/1/algorithm/3.svg?inline';
/* eslint-enable import/no-unresolved */

const illustrations = [
    Illn1,
    Illn2,
    Illn3,
];

export default {
    name: 'algorithm-desktop',
    components: {
        ArticleIcon,
        UiParagraph,
        UiTitle,
        AlgorithmLink,
    },
    props: {
        links: Array,
        required: () => true,
    },
    computed: {
        data: () => [
            'НАЛИЧИЕ<br/> СИМПТОМОВ',
            'ОБСЛЕДОВАНИЕ<br/> НАСЕЛЕНИЯ',
            '<em>ПОСЛЕ ПЕРЕНЕСЕННОГО<br/> ЗАБОЛЕВАНИЯ COVID-19</em>',
        ],
        illn() {
            return {
                component: illustrations[this.active] || null,
                key: `${this.active}__${illustrations.length}`,
            };
        },
        style() {
            const coeff = this.active / (this.data.length - 1);

            return {
                scale: {
                    // transform: `scaleX(${coeff + 0.15})`,
                    transform: `translateX(${(coeff - 0.25) * 100}%) scaleX(0.5)`,
                },
            };
        },
    },
    watch: {
        active(newVal, oldVal) {
            this.transitionName = newVal > oldVal ? 'algorithm-right' : 'algorithm-left';
        },
    },
    data: () => ({
        active: 0,
        transitionName: 'algorithm-right',
    }),
};
</script>

<style lang="scss" scoped>
.data {
    --trf: 3.5%;

    width: rem(750);
    margin: rem(54) auto;

    .steps {
        width: rem(570);
        margin-top: rem(62);
        margin-left: rem(84);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .step {
            position: relative;
            cursor: pointer;

            .icon-wrap {
                width: rem(84);
                height: rem(73);
                position: relative;
                transform: scale(1);
                transition: transform 0.3s ease-in-quad;

                .icon {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;

                    &.active-icon {
                        z-index: 10;
                        opacity: 0;
                        transition: opacity 0.275s ease-in-quad;
                    }

                    > img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .count {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 99999;
                    font-family: var(--font-accent);
                    font-size: rem(24);
                    font-weight: 700;
                    line-height: 1;
                    transform: translate(-50%, -50%);
                    color: var(--teal);
                    transition: color 0.25s ease-in-out-quad;
                }
            }

            .title {
                margin-top: rem(8);
                position: absolute;
                top: 100%;
                left: rem(16);
            }

            .active-wrap {
                width: rem(39);
                position: absolute;
                top: 100%;
                right: 100%;

                .icon {
                    width: 100%;
                    height: rem(34);
                    transform: translateY(-8px);
                    opacity: 0;
                    transition: transform 0.4s ease-in-quad, opacity 0.3s ease-in-quad;
                }

                .pointer {
                    width: rem(8);
                    margin: rem(26) auto 0;
                    display: block;
                    opacity: 0;
                    transition: transform 0.45s ease-in-quad, opacity 0.3s ease-in-quad;
                }

                .external-links-wrap {
                    height: rem(160);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    position: absolute;
                    top: rem(84);
                    left: rem(42);
                    z-index: 100;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-quad;
                }
            }

            &.active,
            &.prev {
                .icon-wrap {
                    .icon {
                        &.active-icon {
                            opacity: 1;
                            transition: opacity 0.45s ease-out-quad;
                        }
                    }

                    .count {
                        color: white;
                    }
                }
            }

            &.active {
                .active-wrap {
                    .icon {
                        transform: translateY(0);
                        opacity: 1;
                        transition: transform 0.45s ease-out-quad, opacity 0.3s ease-out-quad;
                        transition-delay: 0.45s;
                    }

                    .pointer {
                        opacity: 1;
                        transition: opacity 0.3s ease-out-quad;
                        transition-delay: 0.45s;
                    }

                    .external-links-wrap {
                        opacity: 1;
                        transition: opacity 0.3s ease-out-quad;
                        transition-delay: 0.45s;
                    }
                }
            }

            &:hover {
                .icon-wrap {
                    transform: scale(1.075);
                    transition: transform 0.3s ease-out-quad;
                }
            }
        }

        .scale {
            width: 80%;
            height: 3px;
            position: absolute;
            top: calc(50% - 4px);
            left: 10%;
            z-index: -1;
            background-color: var(--light-green);
            overflow: hidden;

            .inner {
                width: 100%;
                height: 100%;
                background-color: var(--violet);
                transform-origin: 0% 50%;
                transition: transform 0.35s ease-in-out-quad;
            }
        }
    }

    .content {
        width: 100%;
        margin-top: rem(262);

        .illn {
            overflow: visible;
            transform-origin: 0% 0%;
            transform: scale(1.125);

            @media (--viewport-laptop-m) {
                transform: scale(1);
            }
        }
    }
}

.algorithm-left {
    &-enter {
        opacity: 0;
        transform: translateX(calc(-1 * var(--trf)));

        &-to {
            opacity: 1;
            transform: translateX(0);
        }

        &-active {
            transition: opacity 0.25s ease-out-quad, transform 0.4s ease-out-quad;
        }
    }

    &-leave {
        opacity: 1;
        transform: translateX(0);

        &-to {
            opacity: 0;
            transform: translateX(var(--trf));
        }

        &-active {
            transition: opacity 0.3s ease-in-quad, transform 0.4s ease-in-quad;
        }
    }
}

.algorithm-right {
    &-enter {
        opacity: 0;
        transform: translateX(var(--trf));

        &-to {
            opacity: 1;
            transform: translateX(0);
        }

        &-active {
            transition: opacity 0.25s ease-out-quad, transform 0.4s ease-out-quad;
        }
    }

    &-leave {
        opacity: 1;
        transform: translateX(0);

        &-to {
            opacity: 0;
            transform: translateX(calc(-1 * var(--trf)));
        }

        &-active {
            transition: opacity 0.3s ease-in-quad, transform 0.4s ease-in-quad;
        }
    }
}
</style>
